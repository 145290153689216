.primaryColor {
  color: #1d1974 !important;
}
.primaryBgColor {
  background-color: #1d1974 !important;
}
.secondaryColor {
  color: #f1b633 !important;
}
.secondaryBgColor {
  background-color: #f1b633 !important;
}
.secondaryLightColor {
  background-color: rgba(241, 182, 51, 0.3) !important;
}
.fitImage {
	height: 8rem !important;
	object-fit: cover;
	width: fit-content;
	cursor: -moz-zoom-in; 
	cursor: -webkit-zoom-in; 
	cursor: zoom-in;
}
.fitImage:hover {
	cursor: zoom-in;
	cursor: -moz-zoom-in; 
	cursor: -webkit-zoom-in; 
	cursor: zoom-in; 
}

.fitImagelarge {
	height: 10rem !important;
	object-fit: cover;
	width: fit-content;
	cursor: -moz-zoom-in; 
	cursor: -webkit-zoom-in; 
	cursor: zoom-in;
}
.fitImageLarge:hover {
	cursor: zoom-in;
	cursor: -moz-zoom-in; 
	cursor: -webkit-zoom-in; 
	cursor: zoom-in; 
}
*:focus {
    outline: none !important;
}